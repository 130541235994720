import Modal from 'antd/lib/modal'
import 'antd/lib/modal/style/css'

const adConfirm = Modal.confirm

const actions = {
  delete: { danger: true },
  destroy: {
    danger: true,
    title: 'confirmDestroyTitle',
    message: 'confirmDestroyMessage',
  },
  requestDeletion: {
    danger: true,
    title: 'content:requestDeletionPopupTitle',
    message: 'content:requestDeletionPopupMessage',
  },
  restore: {},
  block: { danger: true },
  unblock: {},
  disable: { danger: true },
  enable: {},
}

// Confirmation Modal
function confirm({
  action,
  content,
  recordType,
  record,
  okText,
  cancelText,
  onConfirm,
  title,
  t,
}) {
  if ((title && content && okText) || Object.keys(actions).includes(action)) {
    const {
      danger,
      title: _title = 'confirmTitle',
      message: _message = 'confirmMessage',
    } = actions[action] || {}

    const actionAndRecordType = {
      action: `${t(action)}`.toLowerCase(),
      recordType: t(recordType),
    }

    return adConfirm({
      title: t(title) || t(_title, actionAndRecordType),
      content: t(content) || t(_message, { ...actionAndRecordType, record }),
      okText: t(okText || `${action}`),
      okType: danger ? 'danger' : 'primary',
      cancelText: cancelText || t('cancel'),
      maskClosable: true,
      onOk: onConfirm,
      onCancel: () => {},
    })
  } else {
    return null
  }
}

Modal.confirm = confirm

export default Modal
