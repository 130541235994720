import React, { lazy } from 'react'

import { Route, Switch } from 'react-router-dom'

const Login = lazy(() => import('pages/session/Login'))
const ResetPassword = lazy(() => import('pages/session/ResetPassword'))
const ForgotPassword = lazy(() => import('pages/session/ForgotPassword'))

function PublicRouter() {
  return (
    <Switch>
      <Route path="/reset-password/:token">
        <ResetPassword />
      </Route>
      <Route path="/request-password-reset">
        <ForgotPassword />
      </Route>
      <Route>
        <Login />
      </Route>
    </Switch>
  )
}

export default PublicRouter
