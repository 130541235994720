import { action, decorate, observable } from 'mobx'

import i18n from 'config/i18n'
import Locale from 'models/Locale'
import ApiStore from 'stores/ApiStore'
import { arrayOf } from 'utils/arrays'

class LocalesStore extends ApiStore {
  constructor() {
    super({
      model: Locale,
      defaultResource: 'locales',
      paginable: false,
    })
  }

  // Observers:

  currentLocale = null
  currentLocaleCode = null
  locales = []
  localeUI
  filters = []

  // Actions

  setCurrentLocale = currentLocale => {
    this.currentLocale = currentLocale
    return this
  }

  setLocales = locales => {
    this.locales = arrayOf({ model: Locale, withItems: locales })
    return this
  }

  setLocaleUI = localeUI => {
    this.localeUI = localeUI
    return this
  }

  // Others

  loadLocales = ({ filter, onSuccess } = {}) => {
    this.getList({
      query: { filter },
      onSuccess: data => {
        this.setLocales(data)
        onSuccess && onSuccess()
      },
    })
  }

  setCurrentLocaleByCode = (localeCode = 'en-US') => {
    // Set the code (string)
    this.currentLocaleCode = localeCode

    // Set the locale (object)
    this.setCurrentLocale(this.getLocaleByCode(localeCode))

    return this
  }

  setLocaleUIByCode = (localeCode = 'en-US') => {
    for (const locale of this.locales) {
      if (locale.code === localeCode) {
        this.setLocaleUI(locale)
        i18n.changeLanguage(locale.languageCode)
      }
    }

    return this
  }

  // Returns a locale's label in current locale ui
  getLocaleLabel = locale => locale.label(this.localeUI.code)

  getLocaleByCode = localeCode =>
    this.locales.find(({ code }) => code === localeCode)
}

decorate(LocalesStore, {
  currentLocale: observable,
  currentLocaleCode: observable,
  locales: observable,
  localeUI: observable,

  setCurrentLocale: action,
  setCurrentLocaleByCode: action,
  setLocales: action,
  setLocaleUI: action,
})

const localesStore = new LocalesStore()

export default localesStore
