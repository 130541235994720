import { isObservableArray } from 'mobx'

import translate from 'utils/Translate'
import { isFunction } from 'utils/functions'
import { isSet, omit, isObject } from 'utils/objects'

/**
 * Returns a string form an object
 *
 * @param {object} object
 * @param {function|string} attr a function or the name of the attibute we want to read
 */
export function getStringFromObject(object, attr) {
  return isFunction(attr)
    ? attr(object)
    : isObject(object[attr])
    ? translate(object[attr])
    : object[attr]
}

/**
 * Checks if the thing is an array
 *
 * @param {array} thing
 */
export function isArray(thing) {
  return isObservableArray(thing) || Array.isArray(thing)
}

/**
 * Checks if an array is empty
 *
 * @param {array} array
 *
 * @return Boolean
 */
export function isEmpty(array) {
  return isArray(array) && array.length === 0
}

/**
 * Checks if an array is not empty
 *
 * @param {array} array
 *
 * @return Boolean
 */
export function isNotEmpty(array) {
  return !isEmpty(array)
}

export function range({ start = 0, end }) {
  return Array.from({ length: end - start }, (v, k) => k + start)
}

export function arrayOf({ model: Model, withItems: items, extra = {} }) {
  return items ? [...items].map(item => new Model({ ...item, ...extra })) : null
}

/**
 * Removes empty items in an array
 *
 * @param {Array} items array of items
 */
export function removeEmpty(items) {
  return items.filter(Boolean)
}

/**
 * Conditionally reverses an array
 *
 * @param {Boolean} condition
 * @param {Array} items
 */
export function reverseIf(condition = true, items = []) {
  return condition ? items.reverse() : items
}

/**
 * Converts a two level array into a one level array
 *
 * @param {Array} items
 */
export function flatten(items) {
  return items.reduce((acc, val) => acc.concat(val), [])
}

function getTreeSelectProps(item, { getChildren, label, value }) {
  const children = isFunction(getChildren) ? getChildren(item) : item.children

  return {
    children: children
      ? asSelectOptions(children, {
          asTree: true,
          getChildren,
          label,
          value,
        })
      : null,
    hasChildren: isSet(item.hasChildren)
      ? item.hasChildren
      : isArray(children) && children.length > 0,
  }
}

/**
 * Returns an array of items for a ui/Select or ui/TreeSelect
 *
 * @param {array} items
 * @param {object} options
 */
export function asSelectOptions(
  items = [],
  {
    asTree = false,
    getChildren = null,
    label = 'label',
    value = 'id',
    disabled = false,
  } = {}
) {
  return isArray(items)
    ? items.map(item => ({
        text: getStringFromObject(item, label), // isFunction(label) ? label(item) : translate(item[label]),
        value: isFunction(value) ? value(item) : item[value],
        disabled: isFunction(disabled)
          ? disabled(item)
          : item.disabled || disabled,
        item,
        ...(asTree
          ? getTreeSelectProps(item, { getChildren, label, value })
          : {}),
      }))
    : []
}

/**
 * Returns an array of items for a ui/Form/CheckboxGroup
 *
 * @param {array} items
 * @param {object} options Customize `label` and `value` fields
 *
 * @returns array
 */
export function asCheckboxOptions(
  items = [],
  { label = 'label', value = 'value' }
) {
  return items.map(item => ({
    label: getStringFromObject(item, label), //isFunction(label) ? label(item) : translate(item[label]),
    value: getStringFromObject(item, value), //isFunction(value) ? value(item) : item[value],
    item,
  }))
}

/**
 * Returns an array of items for a ui/Form/Transfer
 * @param {array} items
 * @param {object} options
 */
export function asTransferItems(items = [], { title = 'id', key = 'id' }) {
  return items.map(item => ({
    title: getStringFromObject(item, title),
    key: getStringFromObject(item, key),
  }))
}

/**
 * Given an `array` of objects, returns a filtered version that excludes those where their `condition` attribute is `false`
 *
 * @param {array} items
 *
 * @returns array
 */
export function getConditionalItems(items) {
  return isArray(items)
    ? items
        .filter(({ condition }) => !isSet(condition) || Boolean(condition))
        .map(item => ({
          ...omit(item, 'condition'),
        }))
    : []
}
