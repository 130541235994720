//  Roles
import { admin, manager, userManager, entityManager } from 'config/roles'

export default () => {
  return [
    // {
    //   url: '/dashboard',
    //   label: 'menu:dashboard',
    //   icon: 'tachometer-alt',
    //   roles: all,
    // },
    {
      divider: true,
    },
    {
      url: '/courses',
      label: 'menu:courses',
      icon: 'book',
      roles: manager,
    },
    {
      url: '/categories',
      label: 'menu:categories',
      icon: 'tags',
      roles: [...entityManager, ...manager],
    },
    {
      divider: true,
    },
    {
      url: '/groups',
      label: 'menu:groups',
      icon: 'users',
      roles: userManager,
    },
    {
      url: '/users',
      label: 'menu:users',
      icon: 'user-friends',
      roles: userManager,
    },
    {
      url: '/students',
      label: 'menu:students',
      icon: 'user-friends',
      roles: userManager,
    },
    {
      divider: true,
    },
    {
      url: '/entities',
      label: 'menu:entities',
      icon: 'sitemap',
      roles: admin,
    },
    {
      url: '/admin/roles',
      label: 'menu:roles',
      icon: 'id-badge',
      roles: admin,
    },
    {
      divider: true,
    },
    {
      key: '/settings',
      label: 'menu:entitySettings',
      icon: 'info-circle',
      roles: entityManager,
      items: [
        {
          url: '/settings/details',
          label: 'menu:entityDetails',
          icon: 'cog',
        },
        {
          url: '/settings/countries',
          label: 'menu:countries',
          icon: 'globe-americas',
          roles: entityManager,
        },
        {
          url: '/settings/imprint',
          label: 'menu:entityImprint',
          icon: 'file-alt',
        },
        {
          url: '/settings/terms',
          label: 'menu:entityTermsOfUse',
          icon: 'file-alt',
        },
        {
          url: '/settings/privacy',
          label: 'menu:entityPrivacy',
          icon: 'file-alt',
        },
      ],
    },
  ]
}
