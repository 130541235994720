import React, { lazy, Suspense } from 'react'

import PropTypes from 'prop-types'

import { inject, observer } from 'mobx-react'
import { BrowserRouter } from 'react-router-dom'

import PrivateRouter from 'routes/PrivateRouter'
import Loading from 'ui/Loading'

// import AppLoading from './AppLoading'
import BannerNotifications from './BannerNotifications'
import SessionMenu from './SessionMenu'
import SiderMenu from './SiderMenu'

import styles from './MainLayout.module.scss'

const Logo = lazy(() => import('ui/Logo'))
const SiderToggle = lazy(() => import('ui/SiderToggle'))

function MainLayout({ appState, authStore }) {
  const { closeSider, openSider, toggleSider } = appState

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <div
          className={`${styles.wrapper} ${
            styles[`sider--${openSider ? 'open' : 'closed'}`]
          } sider--${openSider ? 'open' : 'closed'}`}
        >
          <div className={styles.header}>
            <div className={styles.title}>
              <SiderToggle
                className={styles.siderToggle}
                isOpen={openSider}
                onToggle={toggleSider}
                iconOpen="bars"
                iconClosed="bars"
                iconClass={`${styles.siderToggleIcon} ${
                  openSider ? styles.siderToggleIconOpen : ''
                }`}
              />
              <Logo />
            </div>
            <SessionMenu hideName={appState.smScreen} />
          </div>
          <div className={styles.body}>
            <div className={styles.sider}>
              <div className={styles.siderMenu}>
                <SiderMenu />
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.contentOverlay} onClick={closeSider} />

              <BannerNotifications />

              <Suspense fallback={<Loading />}>
                {(authStore.rolesLoaded || true) && <PrivateRouter />}
              </Suspense>
            </div>
          </div>
        </div>
      </Suspense>
    </BrowserRouter>
  )
}

MainLayout.propTypes = {
  appState: PropTypes.object.isRequired,
  authStore: PropTypes.object.isRequired,
}

export default inject('appState', 'authStore')(observer(MainLayout))
