import React, { lazy } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

import { Route, Redirect, Switch } from 'react-router-dom'

import { admin, manager, userManager, entityManager } from 'config/roles'

const CoursesRouter = lazy(() => import('pages/courses/Router'))
const CategoriesRouter = lazy(() => import('pages/categories/Router'))
const EntitiesRouter = lazy(() => import('pages/entities/Router'))
const GroupsRouter = lazy(() => import('pages/groups/Router'))
const NotFound = lazy(() => import('pages/NotFound'))
const Notifications = lazy(() => import('pages/notifications'))
const Profile = lazy(() => import('pages/session/Profile'))
const Roles = lazy(() => import('pages/roles/List'))
const EntitySettingsRouter = lazy(() => import('pages/entity-settings/Router'))
const Settings = lazy(() => import('pages/settings'))
const StudentsRouter = lazy(() => import('pages/students/Router'))
const UsersRouter = lazy(() => import('pages/users/Router'))

function RouteIfAllowed({ authStore, Component, path, roles, redirectTo }) {
  const { rolesLoaded, user } = authStore

  return (
    <Route path={path}>
      {!rolesLoaded || (user && user.isAllowed(roles)) ? (
        <Component />
      ) : (
        <Redirect to={redirectTo} />
      )}
    </Route>
  )
}

RouteIfAllowed.propTypes = {
  authStore: PropTypes.object,
  Component: PropTypes.object,
  path: PropTypes.string,
  redirectTo: PropTypes.string,
  roles: PropTypes.array,
}
RouteIfAllowed.defaultProps = {
  redirectTo: '/',
}

const RouteOrRedirect = inject('authStore')(observer(RouteIfAllowed))

function RedirectToHome({ authStore }) {
  const { rolesLoaded, user } = authStore

  if (!rolesLoaded || !user) {
    return <Redirect to="/user/settings" />
  } else if (user.isAllowed(manager)) {
    return <Redirect to="/courses" />
  } else if (user.isAllowed(entityManager)) {
    return <Redirect to="/settings/countries" />
  } else if (user.isAllowed(userManager)) {
    return <Redirect to="/groups" />
  } else {
    return <Redirect to="/user/settings" />
  }
}

RedirectToHome.propTypes = {
  authStore: PropTypes.object,
  path: PropTypes.string,
}

const HomeRedirect = inject('authStore')(observer(RedirectToHome))

function PrivateRouter() {
  return (
    <Switch>
      <Route path="/" exact>
        <HomeRedirect />
      </Route>

      <RouteOrRedirect
        path="/courses"
        Component={CoursesRouter}
        roles={manager}
      />

      <RouteOrRedirect
        path="/categories"
        Component={CategoriesRouter}
        roles={[...entityManager, ...manager]}
      />

      <RouteOrRedirect
        path="/groups"
        Component={GroupsRouter}
        roles={userManager}
      />

      <RouteOrRedirect
        path="/users"
        Component={UsersRouter}
        roles={userManager}
      />
      <RouteOrRedirect
        path="/students"
        Component={StudentsRouter}
        roles={userManager}
      />

      <RouteOrRedirect
        path="/entities"
        Component={EntitiesRouter}
        roles={admin}
      />

      <RouteOrRedirect path="/admin/roles" Component={Roles} roles={admin} />

      <Route path="/user/notifications">
        <Notifications />
      </Route>
      <Route path="/user/profile">
        <Profile />
      </Route>
      <Route path="/user/settings/:tab?">
        <Settings />
      </Route>

      <RouteOrRedirect
        path="/settings"
        Component={EntitySettingsRouter}
        roles={entityManager}
      />

      <Redirect from="/index.html" to="/" />
      <Redirect from="/index" to="/" />
      <Redirect from="/dashboard" to="/" />

      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

PrivateRouter.propTypes = {
  authStore: PropTypes.object,
}

export default inject('authStore')(observer(PrivateRouter))
