import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import markdownJsx from 'i18next-markdown-jsx-plugin'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(markdownJsx)
  .init({
    fallbackLng: ['en'],
    load: 'languageOnly', // all, languageOnly

    backend: {
      loadPath: '/locales/{{ns}}/{{lng}}.json',
      addPath: '/locales/{{ns}}/{{lng}}.missing.json',
    },

    defaultNS: 'common',
    fallbackNS: 'common',
    ns: ['common', 'session', 'user'],

    keySeparator: '.',
    nsSeparator: ':',
    pluralSeparator: '_',
    contextSeparator: '_',

    saveMissing: false,
    debug: false,

    cache: {
      enabled: true,
    },

    // react i18next special options (optional)
    react: {
      useSuspense: true,
    },
  })

export default i18n
