import { computed, decorate, observable } from 'mobx'

class Role {
  // Observables
  id = null
  enabled = true
  deleted = false
  createdAt = new Date()
  updatedAt = new Date()

  name = ''
  getNotifications = false

  constructor(args) {
    Object.assign(this, args)
  }

  // Computed

  get notAdmin() {
    return this.name !== 'admin'
  }
}

decorate(Role, {
  id: observable,
  enabled: observable,
  deleted: observable,
  createdAt: observable,
  updatedAt: observable,

  notAdmin: computed,

  getNotifications: observable,
})

export default Role
