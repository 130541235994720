import { action, computed, decorate, observable } from 'mobx'

import { slugify, randomString } from 'utils/strings'

export default class Entity {
  constructor({ brightcoveAccounts, config, ...args } = {}) {
    Object.assign(this, args)

    this.brightcoveAccounts = brightcoveAccounts || []
    this.config = {
      backendURL: '',
      frontendURL: '',
      domains: [],
      email: {},
      ...(config || {}),
    }
  }

  // Observables
  id = null
  isEnabled = true
  isDeleted = false
  createdAt = new Date()
  updatedAt = new Date()

  address = {}
  brightcoveAccounts = []
  config = {
    backendURL: '',
    frontendURL: '',
    domains: [],
    email: {},
  }
  emailFooter = ''
  defaultWelcomeMessage = ''
  description = ''
  email = null
  imprint = []
  links = []
  scripts = []
  logo = null
  favicon = null
  name = ''
  newsletterEnabled = false
  eventAdvertisementEnabled = false
  nameFieldsEnabled = false
  acceptTermsOfUse = ''
  acceptEventAdvertisement = ''
  acceptNewsletter = ''
  phone = ''
  privacy = []
  siteTitle = ''
  social = {}
  showLegalLinks = false
  termsOfUse = []
  website = ''

  anonymizeIp = true
  bingSiteVerification = ''
  googleTrackingId = ''
  googleSiteVerification = ''
  msnSiteVerification = ''
  yahooSiteVerification = ''

  // Computed

  get visible() {
    return this.isEnabled && !this.isDeleted
  }

  // Actions

  update = ({ ...args }) => {
    Object.assign(this, args)
  }

  updateImprint(imprint) {
    this.imprint = imprint
  }

  updatePrivacy(privacy) {
    this.privacy = privacy
  }

  updateTermsOfUse(termsOfUse) {
    this.termsOfUse = termsOfUse
  }

  addBrightcoveAccount = name => {
    const newAccount = {
      name,
      accountId: slugify(name),
    }

    if (this.brightcoveAccounts.find(a => a.accountId === newAccount.accountId))
      newAccount.accountId = `${newAccount.accountId}_${randomString(3)}`

    this.brightcoveAccounts.push(newAccount)
  }

  removeBrightcoveAccount = accountId => {
    const filteredAccounts = this.brightcoveAccounts.filter(
      account => account.accountId !== accountId
    )

    this.brightcoveAccounts.replace(filteredAccounts)
  }

  resetBrightcoveAccounts = originalAccounts => {
    this.brightcoveAccounts.replace(originalAccounts)
  }

  getBrightcoveAccounts = (accounts = []) => {
    if (!accounts) return []

    const bgAccounts = []
    for (const [, { name, accountId, playerId }] of Object.entries(accounts)) {
      bgAccounts.push({ name, accountId, playerId })
    }

    return bgAccounts
  }

  addLink = url => {
    const urlSegments = url.split(/[/#]/)
    const label = urlSegments[urlSegments.length - 1]
    this.links.push({ url, label })
  }

  removeLink = url => {
    this.links.replace(this.links.filter(l => l.url !== url))
  }

  resetLinks = originalLinks => {
    this.links.replace(originalLinks)
  }

  getLinks = (links = []) => {
    if (!links) return []

    const entityLinks = []
    for (const [, { label, url }] of Object.entries(links)) {
      entityLinks.push({ label, url })
    }

    return entityLinks
  }

  addScript = name => {
    this.scripts.push({
      id: randomString(10),
      name,
      code: '// write some JavaScript here...',
      includesCookies: true,
    })
  }

  removeScript = index => {
    const script = this.scripts.slice()[index]

    this.scripts.remove(
      this.scripts.filter(s => s !== null && s.id !== script?.id)
    )
  }

  resetScripts = originalScripts => {
    this.scripts.replace(originalScripts)
  }

  addDomain = domain => {
    this.config.domains.push(domain)
  }

  removeDomain = domain => {
    this.config.domains.replace(this.config.domains.filter(d => d !== domain))
  }

  resetDomains = originalDomains => {
    this.config.domains.replace(originalDomains)
  }

  setEnabled = value => (this.isEnabled = value)
  setDeleted = value => (this.isDeleted = value)
}

decorate(Entity, {
  id: observable,
  enabled: observable,
  deleted: observable,
  createdAt: observable,
  updatedAt: observable,

  visible: computed,

  address: observable,
  brightcoveAccounts: observable,
  config: observable,
  defaultWelcomeMessage: observable,
  description: observable,
  email: observable,
  emailFooter: observable,
  imprint: observable,
  logo: observable,
  favicon: observable,
  name: observable,
  newsletterEnabled: observable,
  eventAdvertisementEnabled: observable,
  nameFieldsEnabled: observable,
  acceptTermsOfUse: observable,
  acceptEventAdvertisement: observable,
  acceptNewsletter: observable,
  links: observable,
  scripts: observable,
  phone: observable,
  privacy: observable,
  siteTitle: observable,
  social: observable,
  showLegalLinks: observable,
  termsOfUse: observable,
  website: observable,

  anonymizeIp: observable,
  bingSiteVerification: observable,
  googleTrackingId: observable,
  googleSiteVerification: observable,
  msnSiteVerification: observable,
  yahooSiteVerification: observable,

  update: action,
  updateImprint: action,
  updatePrivacy: action,
  updateTermsOfUse: action,
  addBrightcoveAccount: action,
  removeBrightcoveAccount: action,
  resetBrightcoveAccounts: action,
  addLink: action,
  removeLink: action,
  resetLinks: action,
  addScript: action,
  removeScript: action,
  resetScripts: action,
  addDomain: action,
  removeDomain: action,
  resetDomains: action,
  setEnabled: action,
  setDeleted: action,
})
