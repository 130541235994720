import { action, decorate, observable } from 'mobx'

import Entity from 'models/Entity'
import Role from 'models/Role'
import User from 'models/User'

import { arrayOf } from 'utils/arrays'

class Group {
  // Observables
  id = null
  enabled = true
  deleted = false
  createdAt = new Date()
  updatedAt = new Date()

  name
  description
  entity // Main category (of type "entity")
  entityId
  role
  roleId
  isAdmin

  accounts = [] // a.k.a. "Users". Array of Users objects
  candidates = [] // a.k.a. "candidate users". Array of Users objects

  constructor({ accounts, entity, role, ...args } = {}) {
    Object.assign(this, args)

    this.entity = new Entity(entity)
    this.role = new Role(role)

    this.setAccounts(accounts)
  }

  // Actions

  setAccounts = accounts => {
    this.accounts = arrayOf({ model: User, withItems: accounts })
  }

  removeAccounts = accountIds => {
    this.setAccounts(this.accounts.filter(({ id }) => !accountIds.includes(id)))
  }

  setCandidates = accounts => {
    this.candidates = arrayOf({ model: User, withItems: accounts })
  }

  update = ({ name, description, roleId }) => {
    this.name = name
    this.description = description
    this.roleId = roleId
  }

  setEnabled = value => (this.enabled = value)
  setDeleted = value => (this.deleted = value)

  isAdmin = () => {
    return this.role.name === '' || this.role.name === 'admin'
  }
}

decorate(Group, {
  id: observable,
  enabled: observable,
  deleted: observable,
  createdAt: observable,
  updatedAt: observable,

  accounts: observable,
  candidates: observable,
  description: observable,
  entity: observable,
  entityId: observable,
  name: observable,
  role: observable,
  roleId: observable,

  setAccounts: action,
  setCandidates: action,
  setDeleted: action,
  setEnabled: action,
  update: action,
})

export default Group
