import { withEveryMatch } from './strings'

export function getParameters(query = '') {
  const out = {}
  const doReplace = match => (out[match[1]] = match[2])
  withEveryMatch(query, /(?:\?|&amp;|&)([^=]+)=([^&#]+)/, doReplace)
  return out
}

/**
 * Looks for a parameter in location's search and retrieves its value
 * @param {Location} location
 * @param {String} name
 *
 * @returns {String} value
 */
export function getQueryParameter(location, name) {
  const searchParams = new URLSearchParams(location.search)

  return searchParams.get(name)
}

export function replaceParams(url, replacements) {
  let out = url
  const doReplace = match =>
    (out = out.replace(match[0], replacements[match[1]]))
  withEveryMatch(url, /{(\w+)}/, doReplace)
  return out
}

export function getUrlWithQueryDivider(url) {
  return url ? url + (url.match(/\?/i) !== null ? '&' : '?') : ''
}
