import { action, computed, decorate, observable } from 'mobx'

import Group from 'models/Group'
import Locale from 'models/Locale'
import Log from 'models/Log'
import Role from 'models/Role'
import { arrayOf } from 'utils/arrays'

import appConf from 'config/app'
const { cdnUrl } = appConf.apiEndpoints

export default class User {
  // Observables
  id = null
  enabled = true
  deleted = false
  createdAt = new Date()
  updatedAt = new Date()

  countryCode = null
  regionId = null
  firstName = ''
  lastName = ''
  email = ''
  emailVerified
  twoFactorEnabled = false
  avatar
  avatarUrl
  preferences = {}
  lastLoginAt
  defaultSourceLocaleId
  locale
  entityId
  isAdmin = false
  roles = []
  groups = []
  logs = []

  constructor({ groups, locale, logs, roles, ...args } = {}) {
    Object.assign(this, args)

    this.setLocale(locale)
    this.setRoles(roles)
    this.setGroups(groups)
    this.setLogs(logs)
    this.setAvatarUrl()
  }

  // Computed

  get fullName() {
    return `${this.firstName} ${this.lastName}`.trim()
  }

  get profileUrl() {
    return `/users/details/${this.id}`
  }

  get isVerified() {
    return this.emailVerified === true
  }

  // Actions
  update = ({ groups, locale, logs, roles, ...args } = {}) => {
    Object.assign(this, args)

    this.setLocale(locale)
    this.setRoles(roles)
    this.setGroups(groups)
    this.setLogs(logs)
    this.setAvatarUrl()
  }

  setEnabled = value => (this.enabled = value)

  setDeleted = value => (this.deleted = value)

  setCountryCode = value => (this.countryCode = value)

  setRegionId = value => (this.regionId = value)

  setLocale = locale => (this.locale = locale ? new Locale(locale) : null)

  setRoles = roles =>
    (this.roles = roles ? arrayOf({ model: Role, withItems: roles }) : [])

  setGroups = groups =>
    (this.groups = groups ? arrayOf({ model: Group, withItems: groups }) : [])

  setLogs = logs =>
    (this.logs = logs ? arrayOf({ model: Log, withItems: logs }) : [])

  setPreferences = newPreferences => {
    this.preferences = { ...this.preferences, ...newPreferences }
  }

  setAvatarUrl = () => {
    this.avatarUrl = this.avatar
      ? `${cdnUrl}/avatars/${this.id}/${this.avatar}`
      : ''
  }

  // Helpers
  isAllowed = (roles = []) => this.roles.some(r => roles.includes(r.name))
}

decorate(User, {
  enabled: observable,
  deleted: observable,
  updatedAt: observable,

  avatar: observable,
  avatarUrl: observable,
  countryCode: observable,
  regionId: observable,
  email: observable,
  emailVerified: observable,
  firstName: observable,
  groups: observable,
  lastLoginAt: observable,
  lastName: observable,
  locale: observable,
  logs: observable,
  preferences: observable,
  roles: observable,
  twoFactorEnabled: observable,

  fullName: computed,
  isVerified: computed,
  profileUrl: computed,

  setAvatarUrl: action,
  setDeleted: action,
  setEnabled: action,
  setCountryCode: action,
  setRegionId: action,
  setGroups: action,
  setLocale: action,
  setLogs: action,
  setPreferences: action,
  setRoles: action,
  update: action,
})
