import { action, computed, decorate, observable } from 'mobx'

const openSiderKey = 'OpenSider'

class AppState {
  constructor(args) {
    Object.assign(this, args)
    this.getSiderOpen()
  }

  // Observables:

  openSider = false
  loading = false
  offline = false
  reconnected = false
  lastApiCall = null
  lastApiError = null
  mediaQueries = []
  isUpToDate = true
  version = '1.0.0'
  viewportSize = 'sm'

  // Computed:

  get xsScreen() {
    return this.mediaQueries.includes('xs')
  }

  get smScreen() {
    return !this.mediaQueries.includes('md+')
  }

  // Actions:

  toggleSider = () => {
    this.openSider = !this.openSider
    this.storeSiderOpen(this.openSider)
  }

  setOpenSider = value => (this.openSider = value)

  closeSider = () => (this.openSider = false)

  setLoading = value => (this.loading = !!value)

  setOffline = value => (this.offline = value)

  setReconnected = value => (this.reconnected = value)

  setVersion = value => (this.version = value)

  setIsUpToDate = version => (this.isUpToDate = this.version === version)

  setLastApiCall = error => {
    const now = new Date()
    const milisecondsToWait = 2000
    const isEnoughWaitTime = +now > +this.lastApiCall + milisecondsToWait

    if (!this.lastApiCall || isEnoughWaitTime) {
      this.lastApiCall = now
    }

    this.lastApiError = error
  }

  onResize = mediaQueries => {
    this.mediaQueries = mediaQueries

    this.viewportSize = mediaQueries.filter(mq => !mq.match(/\+/gi))[0]
  }

  // Local storage:
  // --------------

  storeSiderOpen = isOpen =>
    localStorage.setItem(openSiderKey, isOpen ? true : false)

  getSiderOpen = async () => {
    const isOpen = await localStorage.getItem(openSiderKey)

    this.setOpenSider(isOpen === 'true')
  }
}

decorate(AppState, {
  isUpToDate: observable,
  lastApiCall: observable,
  lastApiError: observable,
  loading: observable,
  mediaQueries: observable,
  offline: observable,
  openSider: observable,
  reconnected: observable,
  version: observable,
  viewportSize: observable,

  smScreen: computed,
  xsScreen: computed,

  setOpenSider: action,
  closeSider: action,
  onResize: action,
  setIsUpToDate: action,
  setLastApiCall: action,
  setLoading: action,
  setOffline: action,
  setReconnected: action,
  setVersion: action,
  toggleSider: action,
})

const appState = new AppState({
  loading: false,
  offline: false,
  openSider: true,
  reconnected: false,
})

export default appState
