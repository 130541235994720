import React, { useEffect } from 'react'

import { configure as mobxConfigure } from 'mobx'
import { observer, Provider } from 'mobx-react'

import initIconLibrary from 'icons/index.js'
import appState from 'stores/appState'
import AuthStore from 'stores/authStore'
import localesStore from 'stores/localesStore'
import I18nProvider from 'utils/I18nProvider'
import Responsive from 'utils/Responsive'

import LoginLayout from 'ui/layouts/Login/LoginLayout'
import MainLayout from 'ui/layouts/Main/MainLayout'

import './App.scss'
import './AntdOverrides.scss'

const APP_VERSION_CHECK_INTERVAL = 1000 * 60 * 60 // one hour

// Initialzie Icon Library
initIconLibrary()

// Enable mobx "strict mode" (mobx observables can only be modified by actions)
mobxConfigure({ enforceActions: 'observed' })

const authStore = new AuthStore({ localesStore })

function intialLoad() {
  // Check for app's new version:
  // - Check it immediately
  authStore.getClientVersion({ isInitialLoad: true })

  // - And set an interval to check it regularly
  setInterval(() => {
    authStore.getClientVersion({ isInitialLoad: false })
  }, APP_VERSION_CHECK_INTERVAL)

  // Load currentEntity
  authStore.loadEntity()

  localesStore.loadLocales({
    onSuccess: () => {
      // Get user session
      authStore.retrieveSession()
    },
  })
}

function App() {
  useEffect(intialLoad, [])

  return (
    <Provider
      appState={appState}
      authStore={authStore}
      localesStore={localesStore}
    >
      <I18nProvider>
        <Responsive onChange={appState.onResize}>
          {authStore.authenticated ? <MainLayout /> : <LoginLayout />}
        </Responsive>
      </I18nProvider>
    </Provider>
  )
}

export default observer(App)
