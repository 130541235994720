import { action, computed, decorate, observable } from 'mobx'
import merge from 'deepmerge'

import appState from 'stores/appState'

import fetchData, { setClientToken } from 'utils/fetchData'
import { isObject } from 'utils/objects'
import { isFunction } from 'utils/functions'
import { replaceParams } from 'utils/urls'

import appConf from 'config/app'

class ApiStore {
  constructor({
    model,
    defaultResource,
    listEndpoint = 'list',
    itemEndpoint = 'item',
    defaultList = null,
    config = appConf.apiEndpoints,
    paginable = true,
  } = {}) {
    this.model = model
    this.config = config
    this.resource = defaultResource
    this.listEndpoint = listEndpoint
    this.itemEndpoint = itemEndpoint
    this.defaultList = defaultList || defaultResource
    this.paginable = paginable
    this.appState = appState
  }

  // Default filters (override when necessary)
  filters = {
    active: { where: { isDeleted: false, isEnabled: true } },
    disabled: { where: { isDeleted: false, isEnabled: false } },
    deleted: { where: { isDeleted: true } },
    notDeleted: { where: { isDeleted: false } },
    all: {},
  }

  // Observables
  filter = null // string or object
  sortField = null
  sortOrder = 'ASC'
  search = null // string
  loading = false
  total = 0
  filterTotal = 0
  page = 1
  pageSize = 10
  error = null
  success = null

  // Computed values:
  get totalLoaded() {
    return this[this.defaultList] ? this[this.defaultList].length : 0
  }

  get canLoadMore() {
    return this.total > 0 && this.totalLoaded < this.total
  }

  // Actions:
  setLoading = value => {
    this.loading = !!value
    return this
  }

  setTotal = count => {
    this.total = count
    return this
  }

  setSearch = search => {
    this.search = search
    return this
  }

  setFilter = filter => {
    this.filter = filter
    this.setPage(1)
    return this
  }

  setSort = (field, isAscend) => {
    this.sortField = field
    this.sortOrder = isAscend ? 'ASC' : 'DESC'
    return this
  }

  setFilterTotal = count => {
    this.filterTotal = count
    return this
  }

  setPage = page => {
    this.page = page
    return this
  }

  setPageSize = pageSize => {
    this.pageSize = pageSize
    return this
  }

  setPagination = (page, pageSize) => {
    this.setPage(page)
    this.setPageSize(pageSize)
    return this
  }

  setError = error => {
    this.error = error
    return this
  }

  getResource = resource => {
    return this.config.resources[resource] || {}
  }

  getEndpoint = (resource, endpoint) => {
    const { endpoints } = this.getResource(resource)
    return endpoints[endpoint] || this.config.defaults[endpoint] || {}
  }

  getRequest = (resource, endpoint, params) => {
    const { resource: resourceName } = this.getResource(resource)
    const { url, method, count } = this.getEndpoint(resource, endpoint)

    if (url === null) return null

    const requestParams = replaceParams(url, params)
    const requestUrl = `${this.config.rootUrl}${resourceName}${requestParams}`

    return {
      requestUrl,
      requestCountUrl: count
        ? `${requestUrl.replace(/\/$/, '')}/${count}`
        : null,
      method,
    }
  }

  apiCall = async ({
    resource,
    endpoint,
    data,
    params,
    query,
    headers,
    getCount = false,
    uploadFiles,
    onUploadProgress,
    onSuccess,
    onError,
    onFinish,
  }) => {
    const request = this.getRequest(resource || this.resource, endpoint, params)

    if (request) {
      const requestUrl = getCount ? request.requestCountUrl : request.requestUrl

      // sets request client token (only if differs or is null)
      setClientToken(this.config.clientToken)

      const response = await fetchData(requestUrl, {
        loading: this.setLoading,
        method: request.method,
        data,
        params: query,
        headers,
        uploadFiles,
        onUploadProgress,
      })

      // Check for errors and update session time (keep alive).
      this.appState.setLastApiCall(response.error ? response.error.code : null)

      if (response.error) {
        this.setError(response.error)
        if (isFunction(onError)) onError(response.error)
      } else {
        this.setError(null)
        if (isFunction(onSuccess)) onSuccess(response)
      }
    } else {
      this.setError({ error: { name: 'no-endpoint', endpoint } })
    }
    if (onFinish) onFinish()
  }

  getFilter = ({ filter = {} } = {}) => {
    return merge(
      this.filter
        ? isObject(this.filter)
          ? this.filter
          : this.filters[this.filter]
        : {},
      filter
    )
  }

  getList = ({
    resource,
    endpoint,
    query: originalQuery,
    searchFields = [],
    usePagination = true,
    getTotal,
    onSuccess,
    ...rest
  } = {}) => {
    const searchWhere = {}

    // If a search is required
    if (this.search && searchFields.length > 0) {
      // build soft search
      const softSearch = { like: this.search, options: 'i' }

      // add 'or' to where to search in these fields
      searchWhere.or = [
        ...searchFields.map(field => ({
          [field]: softSearch,
        })),
      ]
    }

    // Sets filter
    const filter = this.getFilter(originalQuery)

    if (searchWhere.or) {
      filter.where = {
        ...filter.where,
        ...searchWhere,
      }
    }

    // set pagination filters when resource is paginable and usePagination is enabled
    if (this.paginable && usePagination) {
      filter.limit = this.pageSize
      filter.offset = this.pageSize * (this.page - 1)
    }

    // Build the query object
    const query = {
      ...originalQuery,
      filter,
    }

    // Reset and get total if required
    if (getTotal) {
      this.setTotal(0)
      this.getTotal({
        resource,
        endpoint,
        query,
        ...rest,
      })
    }

    // Get the list
    this.apiCall({
      resource: resource || this.resource,
      endpoint: endpoint || this.listEndpoint,
      query,
      ...rest,
      onSuccess: data => {
        this.setFilterTotal(data.length)

        if (isFunction(onSuccess)) onSuccess(data)
      },
    })
  }

  getTotal = ({
    resource,
    endpoint,
    onSuccess,
    query,
    params,
    setTotal = true,
  } = {}) => {
    const where = query && query.filter ? query.filter.where : null
    this.apiCall({
      resource: resource || this.resource,
      endpoint: endpoint || this.listEndpoint,
      params,
      query: { where },
      getCount: true,
      onSuccess: data => {
        if (setTotal) this.setTotal(data.count)

        if (isFunction(onSuccess)) onSuccess(data.count)
      },
    })
  }

  getItem = ({
    id,
    resource,
    endpoint,
    query,
    onSuccess,
    onError,
    raw = false,
  } = {}) => {
    this.apiCall({
      resource: resource || this.resource,
      endpoint: endpoint || this.itemEndpoint,
      params: { id },
      query,
      onSuccess: data => {
        if (isFunction(onSuccess)) {
          onSuccess(
            !raw && isFunction(this.model) ? new this.model(data) : data
          )
        }
      },
      onError,
    })
  }

  add = ({ onError, onSuccess, ...data }) => {
    this.apiCall({
      endpoint: 'add',
      data,
      onSuccess,
      onError,
    })
  }

  update = ({ item, onError, onSuccess, resource, ...data }) => {
    this.apiCall({
      resource: resource || this.resource,
      endpoint: 'update',
      params: { id: item.id },
      data,
      onSuccess: data => {
        // if the item instance provides an update method, use it
        if (item.update) item.update(data)

        if (isFunction(onSuccess)) {
          onSuccess(data)
        }
      },
      onError,
    })
  }

  enable = ({
    item,
    list,
    onError,
    onSuccess,
    resource,
    updateList = true,
    getTotal = true,
  }) => {
    this.apiCall({
      resource: resource || this.resource,
      endpoint: 'enable',
      params: { id: item.id },
      onSuccess: data => {
        if (item.setEnabled) item.setEnabled(true)
        // Update the list by removing this item from it
        // (It's expected that the list shows disabled items)
        if (updateList) this.removeItemFromList({ item, list, getTotal })

        if (isFunction(onSuccess)) {
          onSuccess(data)
        }
      },
      onError,
    })
  }

  disable = ({
    item,
    list,
    onError,
    onSuccess,
    resource,
    updateList = true,
    getTotal = true,
  }) => {
    this.apiCall({
      resource: resource || this.resource,
      endpoint: 'disable',
      params: { id: item.id },
      onSuccess: data => {
        // if the item instance provides a setEnabled method, use it
        if (item.setEnabled) item.setEnabled(false)
        // Update the list by removing this item from it
        if (updateList) this.removeItemFromList({ item, list, getTotal })

        if (isFunction(onSuccess)) onSuccess(data)
      },
      onError,
    })
  }

  removeItemFromList = ({ item, list = this.defaultList, getTotal = true }) => {
    if (item && this[list]) {
      this[list].remove(item)
    }

    if (getTotal) {
      this.getTotal({
        query: {
          filter: this.getFilter(),
        },
      })
    }
  }

  delete = ({
    item,
    list,
    onError,
    onSuccess,
    resource,
    updateList = true,
    getTotal = true,
  }) => {
    this.apiCall({
      resource: resource || this.resource,
      endpoint: 'delete',
      params: { id: item.id },
      onSuccess: data => {
        // if the item instance provides a setDeleted method, use it
        if (item.setDeleted) item.setDeleted(true)
        // Update the list by removing this item from it
        if (updateList) this.removeItemFromList({ item, list, getTotal })
        if (isFunction(onSuccess)) onSuccess(data)
      },
      onError,
    })
  }

  restore = ({
    item,
    list,
    onError,
    onSuccess,
    updateList = true,
    getTotal = true,
  }) => {
    this.apiCall({
      endpoint: 'restore',
      params: { id: item.id },
      onSuccess: data => {
        // if the item instance provides a setDeleted method, use it
        if (item.setDeleted) item.setDeleted(false)
        // Update the list by removing this item from it
        // (It's expected that the list shows deleted items)
        if (updateList) this.removeItemFromList({ item, list, getTotal })

        if (isFunction(onSuccess)) onSuccess(data)
      },
      onError,
    })
  }
}

decorate(ApiStore, {
  error: observable,
  filter: observable,
  filterTotal: observable,
  loading: observable,
  page: observable,
  pageSize: observable,
  search: observable,
  sortField: observable,
  sortOrder: observable,
  success: observable,
  total: observable,

  canLoadMore: computed,
  totalLoaded: computed,

  removeItemFromList: action,
  setError: action,
  setFilter: action,
  setFilterTotal: action,
  setLoading: action,
  setPage: action,
  setPageSize: action,
  setSearch: action,
  setSort: action,
  setTotal: action,
})

export default ApiStore
