import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'

import { inject, observer } from 'mobx-react'
import { useHistory, useLocation } from 'react-router'

import Modal from 'ui/Modal'
import Menu from 'ui/Menu'
import { useTranslation } from 'utils/TranslateUI'

import styles from './SessionMenu.module.scss'

const Dropdown = lazy(() => import('ui/Dropdown'))
const UserAvatar = lazy(() => import('ui/UserAvatar'))

const getSelectedKeys = pathname => [pathname, `${pathname}/`]

function getMenuItems(onLogout) {
  return [
    {
      label: 'profile',
      icon: 'user',
      url: '/user/profile',
    },
    {
      label: 'common:settings',
      icon: 'cogs',
      url: '/user/settings',
    },
    { divider: true },
    {
      label: 'logout',
      icon: 'power-off',
      onClick: onLogout,
    },
  ]
}

function SessionMenu({ authStore, hideName, trigger }) {
  const history = useHistory()
  const { pathname } = useLocation()
  const { t } = useTranslation(['session'])

  if (!authStore.user) return null

  function _onLogout() {
    Modal.confirm({
      title: t('logout'),
      content: t('logoutConfirm'),
      okText: t('logout'),
      onConfirm: () => {
        authStore.logout({
          onSuccess: () => history.push('/'),
        })
      },
      t,
    })
  }

  const avatar = (
    <div className="ant-dropdown-link">
      <UserAvatar user={authStore.user} hideName={hideName} />
    </div>
  )

  return (
    <div className={styles.wrap}>
      <Suspense fallback={avatar}>
        <Dropdown
          overlay={
            <Menu
              items={getMenuItems(_onLogout)}
              selectedKeys={getSelectedKeys(pathname)}
              t={t}
            />
          }
          placement="bottomRight"
          trigger={trigger}
        >
          {avatar}
        </Dropdown>
      </Suspense>
    </div>
  )
}

SessionMenu.propTypes = {
  authStore: PropTypes.object,
  hideName: PropTypes.bool,
  trigger: PropTypes.array,
}
SessionMenu.defaultProps = {
  trigger: ['click'],
}

export default inject('authStore')(observer(SessionMenu))
