import { action, autorun, computed, decorate, observable } from 'mobx'

import appConf from 'config/app'
import Entity from 'models/Entity'
import Locale from 'models/Locale'
import User from 'models/User'
import appState from 'stores/appState'
import ApiStore from 'stores/ApiStore'
import { arrayOf } from 'utils/arrays'
import { setAuthToken } from 'utils/fetchData'
import { isFunction } from 'utils/functions'
import { getSecret, getTwoFactorUrl } from 'utils/twoFactor'

const { clientToken, sseNofitications } = appConf.apiEndpoints
const { url } = sseNofitications
const sessionKey = 'UserSession'
const entityIdKey = 'EntityId'

class AuthStore extends ApiStore {
  // Observables

  authToken = null
  initialLoading = true
  rolesLoaded = false
  session = null
  user = null

  currentEntity
  locales = []
  selectedEntityId

  // SSE
  sse
  isReconnect = false

  constructor({ localesStore }) {
    super({
      model: User,
      defaultResource: 'accounts',
    })

    this.appState = appState
    this.localesStore = localesStore

    autorun(() => {
      this.updateSession({
        error: this.appState.lastApiError,
        datetime: this.appState.lastApiCall,
      })
    })
  }

  // SSE

  subscribe = ({ userId }) => {
    this.isReconnect = false
    this.sse = new EventSource(`${url}/${userId}`)

    this.sse.addEventListener(
      'open',
      () => {
        if (this.isReconnect) {
          this.getClientVersion({ isInitialLoad: false })

          if (this.session) {
            this.loadProfile()
          }
        }
      },
      false
    )

    // When we got disconnected from the sse
    this.sse.addEventListener(
      'error',
      e => {
        if (e.target.readyState === EventSource.CLOSED) {
          this.appState.setOffline(true)
        } else if (e.target.readyState === EventSource.CONNECTING) {
          this.isReconnect = true
        }
      },
      false
    )

    // When the client version has been updated
    this.sse.addEventListener(
      'CLIENT_VERSION_UPDATE',
      e => {
        const data = JSON.parse(e.data)
        if (data.token === clientToken)
          this.getClientVersion({ isInitialLoad: false })
      },
      false
    )

    // Account disabled
    this.sse.addEventListener(
      'ACCOUNT_DISABLED',
      () => {
        this.setSession(null)
      },
      false
    )

    // Account updated
    this.sse.addEventListener(
      'ACCOUNT_UPDATED',
      () => {
        this.loadProfile({ onlyProfile: true })
      },
      false
    )

    // Permissions updated
    this.sse.addEventListener(
      'PERMISSIONS_UPDATED',
      () => {
        this.loadProfile()
      },
      false
    )
  }

  unsubscribe = () => {
    if (this.sse) this.sse.close()
  }

  // Computed

  get authenticated() {
    return this.session && this.user
  }

  get dataIsLoading() {
    return this.initialLoading
  }

  // Hooks

  afterSetUser = user => user
  afterCreateSession = session => session
  beforeDestroySession = session => session

  // Actions

  setUser = (data, onlyProfile) => {
    if (this.user && data) {
      this.user.update(data)
    } else {
      this.user = data ? new User(data) : null
    }

    // Set user's default entity, unless only the profile is loaded
    if (this.user && !onlyProfile) {
      this.setRoles(data.roles)
      this.setRolesLoaded(true)
    }

    if (this.user) {
      const { defaultLocale, localeUI } = this.user.preferences

      // Prefered Contents locale
      this.localesStore.setCurrentLocaleByCode(defaultLocale)

      // Prefered User Interface locale
      this.localesStore.setLocaleUIByCode(localeUI)
    }

    this.afterSetUser(this.user)
  }

  setRoles = data => {
    if (this.user) this.user.setRoles(data)
  }

  setRolesLoaded = loaded => {
    this.rolesLoaded = loaded
  }

  // NOTE: method useless for the AMS Course Manager, but necessary to compile ui components of ECL
  setSelectedEntityId = () => null

  setSession = session => {
    if (!session && this.session) {
      // Unsubscribe from sse notifications for the user
      this.unsubscribe()

      this.beforeDestroySession(this.session)
    }

    this.session = session

    this.setAuthToken(session ? session.id : null)
    this.storeSession(session)

    if (session) {
      this.loadProfile()

      // Subscribe to sse notifications for the user
      this.subscribe({
        userId: session.userId,
      })

      this.afterCreateSession(session)
    } else {
      this.setUser(null)
      this.setRolesLoaded(false)
    }
  }

  setSessionData = session => {
    this.session = session
  }

  setAuthToken = token => {
    this.authToken = setAuthToken(token)
  }

  setLocales = locales => {
    this.locales = arrayOf({ model: Locale, withItems: locales })
  }

  setInitialLoading = loading => {
    this.initialLoading = loading
  }

  // Account:
  // --------

  verifyAccount = ({ uid, token, onSuccess, onError } = {}) => {
    this.apiCall({
      endpoint: 'verifyAccount',
      query: {
        uid,
        token,
      },
      onSuccess,
      onError,
    })
  }

  // Profile:
  // --------

  loadProfile = ({ onlyProfile, onSuccess, onError } = {}) => {
    if (this.session && this.session.userId) {
      this.apiCall({
        resource: 'accounts',
        endpoint: 'item',
        params: {
          id: this.session.userId,
        },
        query: {
          filter: { include: ['roles', 'entity'] },
        },
        onSuccess: data => {
          this.setUser(data, onlyProfile)
          isFunction(onSuccess) && onSuccess()
        },
        onError,
      })
    }
  }

  updateProfile = ({ onSuccess, onError, ...data } = {}) => {
    this.apiCall({
      endpoint: 'update',
      params: {
        id: this.session.userId,
      },
      data,
      onSuccess: () => {
        this.loadProfile({ onlyProfile: true, onSuccess, onError })
      },
      onError,
    })
  }

  updateUserPreferences = preferences => {
    this.user.setPreferences(preferences)

    this.updateProfile({ preferences: this.user.preferences })
  }

  // Session:
  // --------

  login = ({ email, password, token, on2FRequired, onSuccess, onError }) => {
    this.setInitialLoading(true)
    this.apiCall({
      resource: 'accounts',
      endpoint: 'login',
      data: {
        email,
        password,
        token,
      },
      onSuccess: data => {
        if (data.twoFactorRequired) {
          isFunction(on2FRequired) && on2FRequired()
        } else {
          this.setSession(data)
        }

        onSuccess && onSuccess()
      },
      onError,
      onFinish: () => {
        this.setInitialLoading(false)
      },
    })
  }

  logout = ({ onSuccess, onError } = {}) => {
    this.apiCall({
      endpoint: 'logout',
      onSuccess: () => {
        this.setSession(null)
        isFunction(onSuccess) && onSuccess()
      },
      onError,
    })
  }

  updateSession = ({ error, datetime }) => {
    switch (error) {
      // If there is an error related to an invalid session or
      // an unauthorized access, the session will we nullified.
      case 'AUTHORIZATION_REQUIRED':
      case 'INVALID_TOKEN': {
        this.appState.setOffline(false)
        this.setSession(null)
        break
      }
      case 'SERVER_OFFLINE': {
        this.appState.setOffline(true)
        break
      }
      default: {
        const { session } = this
        if (session) {
          // Update session's creation date to extend its duration.
          session.created = datetime
        }
        this.setSessionData(session)
        break
      }
    }
  }

  // Change password:
  // ----------------

  changePassword = ({
    passwordCurrent,
    passwordNew,
    token,
    onSuccess,
    onError,
  } = {}) => {
    this.apiCall({
      endpoint: 'changePassword',
      params: {
        id: this.session.userId,
      },
      data: {
        oldPassword: passwordCurrent,
        newPassword: passwordNew,
        token,
      },
      onSuccess,
      onError,
    })
  }

  // Set new password:
  // ----------------

  setPassword = ({ passwordNew, token, onSuccess, onError } = {}) => {
    this.setAuthToken(token)

    this.apiCall({
      endpoint: 'resetPassword',
      data: {
        newPassword: passwordNew,
      },
      onSuccess: () => {
        this.setAuthToken(null)
        isFunction(onSuccess) && onSuccess()
      },
      onError,
    })
  }

  // Request password reset:
  // ----------------
  requestPasswordReset = ({ email, onSuccess, onError } = {}) => {
    this.apiCall({
      endpoint: 'requestPasswordReset',
      data: { email },
      onSuccess,
      onError,
    })
  }

  // Request Account Verification E-mail:
  // ----------------
  requestVerificationEmail = ({ email, onSuccess, onError } = {}) => {
    this.apiCall({
      endpoint: 'requestVerificationEmail',
      data: { email },
      onSuccess,
      onError,
    })
  }

  // Change Email:
  // ----------------

  changeEmail = ({ newEmail, password, token, onSuccess, onError } = {}) => {
    this.apiCall({
      endpoint: 'changeEmail',
      params: {
        id: this.session.userId,
      },
      data: {
        newEmail,
        password,
        token,
      },
      onSuccess,
      onError,
    })
  }

  // Change Avatar:
  // ----------------

  changeAvatar = ({
    file,
    crop,
    onUploadProgress,
    onSuccess,
    onError,
  } = {}) => {
    this.apiCall({
      resource: 'avatars',
      endpoint: 'upload',
      params: { id: this.session.userId },
      data: { file, crop: JSON.stringify(crop) },
      uploadFiles: true,
      onUploadProgress,
      onSuccess,
      onError,
    })
  }

  // Two-Factor:
  // -----------

  get2FASecretAndUrl = () => {
    const { email } = this.user
    const secret = getSecret()
    return {
      secret,
      url: getTwoFactorUrl({ email, secret, issuer: this.currentEntity.name }),
    }
  }

  enable2FA = ({ secret, token, onSuccess, onError }) => {
    // Call the API
    this.apiCall({
      endpoint: 'enable2FA',
      params: {
        id: this.session.userId,
      },
      data: {
        secret,
        token,
      },
      onSuccess: ({ twoFactorCodes }) => {
        onSuccess && onSuccess(twoFactorCodes)
        this.loadProfile({ onlyProfile: true })
      },
      onError,
    })
  }

  disable2FA = ({ password, token, onSuccess, onError }) => {
    this.apiCall({
      endpoint: 'disable2FA',
      params: {
        id: this.session.userId,
      },
      data: {
        password,
        token,
      },
      onSuccess: () => {
        onSuccess && onSuccess()
        this.loadProfile({ onlyProfile: true })
      },
      onError,
    })
  }

  // Entity:
  // --------------
  loadEntity = ({ onSuccess, onError } = {}) => {
    this.apiCall({
      resource: 'entities',
      endpoint: 'currentEntity',
      onSuccess: data => {
        this.setEntity(data)
        isFunction(onSuccess) && onSuccess()
      },
      onError,
    })
  }

  setEntity = entity => {
    this.currentEntity = new Entity(entity)
    const entityId = entity ? entity.id : null
    this.storeEntityId(entityId)
  }

  // NOTE: method useless for AMS Course Manager, but necessary to compile ui components of ECL
  setCurrentEntityById = () => null

  // Local storage:
  // --------------

  storeSession = session =>
    localStorage.setItem(sessionKey, session && JSON.stringify(session))

  retrieveSession = () =>
    this.setSession(JSON.parse(localStorage.getItem(sessionKey)))

  storeEntityId = entityId => localStorage.setItem(entityIdKey, entityId)

  getEntityId = () => localStorage.getItem(entityIdKey)

  // Others:
  // -------

  getClientVersion = ({ isInitialLoad = true } = {}) => {
    this.apiCall({
      resource: 'clients',
      endpoint: 'getVersion',
      onSuccess: version => {
        if (isInitialLoad) {
          this.appState.setVersion(version)
        } else {
          this.appState.setIsUpToDate(version)
        }
      },
    })
  }
}

decorate(AuthStore, {
  authToken: observable,
  currentEntity: observable,
  entities: observable,
  initialLoading: observable,
  locales: observable,
  pendingOperations: observable,
  rolesLoaded: observable,
  selectedEntityId: observable,
  session: observable,
  user: observable,

  authenticated: computed,
  dataIsLoading: computed,

  setAuthToken: action,
  setEntity: action,
  setInitialLoading: action,
  setLocales: action,
  setRolesLoaded: action,
  setSelectedEntityId: action,
  setSession: action,
  setSessionData: action,
  setUser: action,
})

export default AuthStore
