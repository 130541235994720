// Using axios: https://github.com/mzabriskie/axios
import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.validateStatus = status => {
  return status >= 200 && status < 500
}

const convertToFormData = data => {
  const formData = new FormData()

  for (const field of Object.keys(data)) {
    formData.append(field, data[field])
  }

  return formData
}

export const setClientToken = token => {
  if (token !== axios.defaults.headers.common.ClientToken)
    axios.defaults.headers.common.ClientToken = token
}

export const setEntityId = entityId => {
  if (entityId) axios.defaults.headers.common.EntityId = entityId
  else delete axios.defaults.headers.common.EntityId
}

export const setAuthToken = token =>
  (axios.defaults.headers.common.Authorization = token)

export default async (
  url,
  {
    method = 'get',
    data,
    params,
    headers = null,
    loading = value => value,
    uploadFiles = false,
    onUploadProgress,
  }
) => {
  loading(true)
  const extraConfig = {}

  // In case is uploading a local file:
  // - Note: Remote files need to provide a url, and in that case this step is omited
  if (data && uploadFiles && !data.url) {
    // If file upload is required, this wraps data in a FormData
    data = convertToFormData(data)

    headers = {
      'Content-Type': 'multipart/form-data',
      ...headers,
    }

    // Provide the upload progress to the onUploadProgress() callback
    extraConfig['onUploadProgress'] = progress => {
      if (onUploadProgress) {
        var { loaded, total } = progress
        onUploadProgress(Math.round((loaded * 100) / total))
      }
    }
  }

  const req = {
    url,
    method,
    data,
    params,
    headers: {
      ...axios.defaults.headers.common,
      ...headers,
    },
    ...extraConfig,
  }
  try {
    const res = await axios(req)
    // console.log({ req, res })
    const json = await res.data
    loading(false)
    return json.error ? { error: json.error || 'UNDEFINED_ERROR', req } : json
  } catch (e) {
    loading(false)
    const res = e.response
      ? {
          error: 'FETCH_DATA_FAILED',
          status: e.response.status,
          statusText: e.response.statusText,
          req,
        }
      : {
          error: 'SERVER_OFFLINE',
          status: '400',
          statusText: 'Server is not reachable',
          req,
        }
    // console.error(res)
    return res
  }
}
