import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'ui/Icon'
import { isObject } from 'utils/objects'
import { isString } from 'utils/strings'
import translate from 'utils/Translate'

function LabelIcon({ className, icon, label, labelClass, spin, t, uiFirst }) {
  return (
    <span className={className}>
      {icon ? isString(icon) ? <Icon name={icon} spin={spin} /> : icon : null}
      <span className={labelClass}>
        {isObject(label) ? translate(label, { uiFirst }) : t(label)}
      </span>
    </span>
  )
}

LabelIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  labelClass: PropTypes.string,
  spin: PropTypes.bool,
  t: PropTypes.func,
  uiFirst: PropTypes.bool,
}

LabelIcon.defaultProps = {
  className: '',
  labelClass: '',
  t: x => x,
  uiFirst: true,
}

export default LabelIcon
