import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

export { pick, omit, isEmpty }

export const isObject = object => !!(object && typeof object === 'object')

export const isDefined = object => object != null

export const isNotNull = object => !isNull(object)

export const isNull = object => object == null

export const isSet = object => isDefined(object) && isNotNull(object)

export const setMap = (object, { setValue = (key, value) => value } = {}) =>
  isObject(object)
    ? new Map(Object.keys(object).map(key => [key, setValue(key, object[key])]))
    : null
