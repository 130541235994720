import localesStore from 'stores/localesStore'
import { isObject } from 'utils/objects'

/**
 * Functional version of Utils/Translate (usage requires a mobx's observer)
 *
 * Returns text based on this order priority:
 *
 * 1. Requested language
 * 2. Default Content language
 * 3. User Interface language
 * 4. English
 * 5. First of content's languages
 *
 * Params:
 *
 * @param {object} content (object, required) An object with locales as `keys` and text as `values`
 * @param {object} options (object) It supports one or more of the following settings:
 * - `language` `(string)` Use this to force a specific locale
 * - `uiFirst` `(boolean)` If `true`, sets the UI Locale as second in priority.
 * - `fallbackContent` `(string)` Content shows when everything else fails.
 * - `t` `(function)` Translation function used when content is not an object.
 */
export default function translate(content, options = {}) {
  const {
    language = null,
    uiFirst = false,
    modifier = text => text,
    fallbackContent = '',
    t = x => x,
  } = options
  if (!isObject(content)) return t(`${content}`)

  const { currentLocale, localeUI } = localesStore
  const contentLocales = Object.keys(content)

  return modifier(
    localeUI && currentLocale.code
      ? content[language] ||
          content[uiFirst ? localeUI.code : currentLocale.code] ||
          content[uiFirst ? currentLocale.code : localeUI.code] ||
          content['en-US'] ||
          content[contentLocales[0]] ||
          fallbackContent
      : fallbackContent
  )
}
