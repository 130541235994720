export default {
  rootUrl: process.env.REACT_APP_API_URL,
  cdnUrl: process.env.REACT_APP_CDN_URL,
  clientToken: process.env.REACT_APP_CLIENT_TOKEN,
  sseNofitications: {
    url: process.env.REACT_APP_SSE_URL,
  },
  defaults: {
    list: {
      method: 'get',
      url: '/',
      count: 'count',
    },
    add: {
      method: 'post',
      url: '/',
    },
    item: {
      method: 'get',
      url: '/{id}',
    },
    update: {
      method: 'patch',
      url: '/{id}',
    },
    enable: {
      method: 'post',
      url: '/{id}/enable',
    },
    disable: {
      method: 'post',
      url: '/{id}/disable',
    },
    delete: {
      method: 'delete',
      url: '/{id}',
    },
    restore: {
      method: 'post',
      url: '/{id}/restore',
    },
  },
  resources: {
    avatars: {
      resource: 'avatars',
      endpoints: {
        upload: {
          method: 'post',
          url: '/{id}/upload',
        },
      },
    },
    clients: {
      resource: 'clients',
      endpoints: {
        getVersion: {
          method: 'get',
          url: '/version',
        },
      },
    },
    roles: {
      resource: 'roles',
      endpoints: {
        item: {
          method: 'get',
          url: '/{id}',
        },
      },
    },
    countries: {
      resource: 'countries',
      endpoints: {
        item: {
          method: 'get',
          url: '/{id}',
        },
      },
    },
    entityCountries: {
      resource: 'entityCountries',
      endpoints: {
        item: {
          method: 'get',
          url: '/{id}',
        },
        scopes: {
          method: 'get',
          url: '/{id}/scopes',
          count: 'count',
        },
        createScope: {
          method: 'post',
          url: '/{id}/scopes',
        },
      },
    },
    groups: {
      resource: 'groups',
      endpoints: {
        accounts: {
          method: 'get',
          url: '/{id}/accounts',
        },
        addAccounts: {
          method: 'post',
          url: '/{id}/addAccounts',
        },
        candidates: {
          method: 'get',
          url: '/{id}/candidates',
        },
        removeAccounts: {
          method: 'post',
          url: '/{id}/removeAccounts',
        },
      },
    },
    documents: {
      resource: 'documents',
      endpoints: {
        upload: {
          method: 'post',
          url: '/{resourceName}/{containerId}/upload',
        },
      },
    },
    images: {
      resource: 'images',
      endpoints: {
        upload: {
          method: 'post',
          url: '/{resourceName}/{containerId}/upload',
        },
        uploadRemote: {
          method: 'post',
          url: '/{resourceName}/{containerId}/upload-remote',
        },
      },
    },
    locales: {
      resource: 'locales',
      endpoints: {},
    },
    courses: {
      resource: 'courses',
      endpoints: {
        item: {
          method: 'get',
          url: '/{id}',
        },
        lessons: {
          method: 'get',
          url: '/{id}/lessons',
        },
        createLesson: {
          method: 'post',
          url: '/{id}/lessons',
        },
        'TODO:addCategory': {
          method: 'post',
          url: '/{id}/addCategory',
        },
        'TODO:removeCategory': {
          method: 'post',
          url: '/{id}/removeCategory',
        },
      },
    },
    scopes: {
      resource: 'scopes',
      endpoints: {
        item: {
          method: 'get',
          url: '/{id}',
        },
      },
    },
    lessons: {
      resource: 'lessons',
      endpoints: {
        item: {
          method: 'get',
          url: '/{id}',
        },
        slides: {
          method: 'get',
          url: '/{id}/slides',
        },
        createSlide: {
          method: 'post',
          url: '/{id}/slides',
        },
        setPosition: {
          method: 'post',
          url: '/{id}/setPosition',
        },
      },
    },
    slides: {
      resource: 'slides',
      endpoints: {
        item: {
          method: 'get',
          url: '/{id}',
        },
        contents: {
          method: 'get',
          url: '/{id}/contents',
        },
        setPosition: {
          method: 'post',
          url: '/{id}/setPosition',
        },
      },
    },
    questionnaires: {
      resource: 'questionnaires',
      endpoints: {
        questions: {
          method: 'get',
          url: '/{id}/questions',
        },
        createQuestion: {
          method: 'post',
          url: '/{id}/questions',
        },
      },
    },
    questions: {
      resource: 'questions',
      endpoints: {
        setPosition: {
          method: 'post',
          url: '/{id}/setPosition',
        },
      },
    },
    categories: {
      resource: 'categories',
      endpoints: {
        item: {
          method: 'get',
          url: '/{id}',
        },
        addCourse: {
          method: 'post',
          url: '/{id}/addCourse',
        },
        removeCourse: {
          method: 'post',
          url: '/{id}/removeCourse',
        },
      },
    },
    entities: {
      resource: 'entities',
      endpoints: {
        currentEntity: {
          method: 'get',
          url: '/currentEntity',
        },
        countries: {
          method: 'get',
          url: '/{id}/countries',
          count: 'count',
        },
        createCountry: {
          method: 'post',
          url: '/{id}/countries',
        },
        courses: {
          method: 'get',
          url: '/{id}/courses',
          count: 'count',
        },
        createCourse: {
          method: 'post',
          url: '/{id}/courses',
        },
        categories: {
          method: 'get',
          url: '/{id}/categories',
          count: 'count',
        },
        createCategory: {
          method: 'post',
          url: '/{id}/categories',
        },
      },
    },
    accounts: {
      resource: 'accounts',
      endpoints: {
        login: {
          method: 'post',
          url: '/login',
        },
        changePassword: {
          method: 'post',
          url: '/change-password',
        },
        changeEmail: {
          method: 'post',
          url: '/{id}/change-email',
        },
        enable2FA: {
          method: 'post',
          url: '/{id}/enable-2fa',
        },
        disable2FA: {
          method: 'post',
          url: '/{id}/disable-2fa',
        },
        requestPasswordReset: {
          method: 'post',
          url: '/request-password-reset',
        },
        resetPassword: {
          method: 'post',
          url: '/reset-password',
        },
        logout: {
          method: 'post',
          url: '/logout',
        },
        addRole: {
          method: 'post',
          url: '/{id}/addRole',
        },
        removeRole: {
          method: 'post',
          url: '/{id}/removeRole',
        },
      },
    },
    students: {
      resource: 'students',
      endpoints: {
        destroy: {
          method: 'post',
          url: '/{id}/delete-account',
        },
        resendRegistrationEmail: {
          method: 'post',
          url: '/request-verification-email',
        },
      },
    },
    notifications: {
      resource: 'notifications',
      endpoints: {
        markAsRead: {
          method: 'post',
          url: '/{id}/read',
        },
        markAsUnread: {
          method: 'post',
          url: '/{id}/unread',
        },
      },
    },
  },
}
