import React, { lazy, useMemo } from 'react'
import PropTypes from 'prop-types'

import { inject, observer } from 'mobx-react'
import { useLocation } from 'react-router'

import sideMenu from 'config/sideMenu'
import { flatten } from 'utils/arrays'
import { useTranslation } from 'utils/TranslateUI'

const Menu = lazy(() => import('ui/Menu'))

function getCurrentKeys(path) {
  const posibleUrls = path.match(/\/([^/]+)?/g).reduce((acc, current, i) => {
    acc[i] = (acc[i - 1] || '') + current
    return acc
  }, [])

  return flatten(posibleUrls.map(url => [url, `${url}/`]))
}

function getItems(authStore) {
  let renderNextDivider = true
  const { user } = authStore

  return sideMenu(authStore).map(item => {
    const { divider, roles } = item

    if (divider && renderNextDivider) {
      renderNextDivider = false
    } else if (!user || item.roles === undefined || user.isAllowed(roles)) {
      renderNextDivider = true
    } else {
      return null
    }

    return item
  })
}

function SiderMenu({ authStore }) {
  const { pathname } = useLocation()
  const { t } = useTranslation(['menu', 'dashboard'])

  // Calculate the open/selected keys
  const currentKeys = useMemo(() => getCurrentKeys(pathname), [pathname])

  return (
    <Menu
      defaultOpenKeys={currentKeys}
      selectedKeys={currentKeys}
      mode="inline"
      inlineIndent={16}
      items={getItems(authStore)}
      t={t}
    />
  )
}

SiderMenu.propTypes = {
  authStore: PropTypes.object,
}

export default inject('authStore')(observer(SiderMenu))
