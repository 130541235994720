import React, { lazy } from 'react'
import PropTypes from 'prop-types'

import ADMenu from 'antd/lib/menu'
import 'antd/lib/menu/style/css'

import { Link } from 'react-router-dom'

import { removeEmpty } from 'utils/arrays'

import styles from './Menu.module.scss'

const LabelIcon = lazy(() => import('ui/LabelIcon'))
const ConditionalWrap = lazy(() => import('utils/ConditionalWrap'))

function getItems(items) {
  return removeEmpty(
    items.filter(
      item => item && (item.condition === true || item.condition === undefined)
    )
  )
}

function renderItems(items, onClick, t) {
  let renderNextDivider = true

  return items.map((item, i) => {
    const key = item.key || item.url || i

    if (item.divider) {
      if (renderNextDivider) {
        renderNextDivider = false
        return <ADMenu.Divider key={key} />
      } else {
        return null
      }
    } else {
      renderNextDivider = true

      const labelIcon = <LabelIcon label={item.label} icon={item.icon} t={t} />

      if (item.items) {
        return (
          <ADMenu.SubMenu
            title={labelIcon}
            onTitleClick={item.onClick || onClick}
            key={key}
          >
            {renderItems(item.items, onClick, t)}
          </ADMenu.SubMenu>
        )
      }

      return (
        <ADMenu.Item
          key={key}
          disabled={item.disabled}
          className={item.danger ? styles.itemDanger : null}
        >
          <ConditionalWrap
            condition={item.url}
            wrap={children => <Link to={item.url}>{children}</Link>}
            wrapElse={children => (
              <div onClick={item.onClick} className={styles.item}>
                {children}{' '}
                {item.hint ? (
                  <span className={styles.itemHint}>{item.hint}</span>
                ) : (
                  ''
                )}
              </div>
            )}
          >
            {labelIcon}
          </ConditionalWrap>
        </ADMenu.Item>
      )
    }
  })
}

function Menu({ items, onClick, t, ...props }) {
  return (
    <ADMenu onClick={onClick} {...props}>
      {renderItems(getItems(items), onClick, t)}
    </ADMenu>
  )
}

Menu.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
  t: PropTypes.func,
}
Menu.defaultProps = {
  items: [],
}

export default Menu
